import { Resource } from '@opentelemetry/resources';
import { SemanticResourceAttributes } from '@opentelemetry/semantic-conventions';
import { WebTracerProvider, BatchSpanProcessor } from '@opentelemetry/sdk-trace-web';
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http';
import { registerInstrumentations } from '@opentelemetry/instrumentation';
import { FetchInstrumentation } from '@opentelemetry/instrumentation-fetch';
import { DocumentLoadInstrumentation } from '@opentelemetry/instrumentation-document-load';
import { UserInteractionInstrumentation } from '@opentelemetry/instrumentation-user-interaction';
import { XMLHttpRequestInstrumentation } from '@opentelemetry/instrumentation-xml-http-request';

if (process.env.REACT_APP_TRACING_ENABLED) {
	const collectorExporter = new OTLPTraceExporter({
		url: '/traces',
		concurrencyLimit: 10,
	});

	const provider = new WebTracerProvider({
		resource: new Resource({
			[SemanticResourceAttributes.SERVICE_NAME]: 'op-billing-widget',
			'sumup.org.tribe': 'online-payments',
			'sumup.org.squad': 'billing-subscriptions'
		})
	});

	const fetchInstrumentation = new FetchInstrumentation();
	fetchInstrumentation.setTracerProvider(provider);

	provider.addSpanProcessor(new BatchSpanProcessor(collectorExporter));
	provider.register();

	registerInstrumentations({
		instrumentations: [
			new DocumentLoadInstrumentation(),
			new UserInteractionInstrumentation(),
			new XMLHttpRequestInstrumentation(),
			fetchInstrumentation,
		],
		tracerProvider: provider,
	});
}